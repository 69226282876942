import axiosIns from "@/libs/axios";

export const evaluationService = {
  viewEvaluation: async (id) => {
    try {
      const response = await axiosIns.get(
        `/backoffice/evaluation-period/view/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  createEvaluationPeriod: async () => {
    try {
      const response = await axiosIns.get(
        "/backoffice/evaluation-period/create"
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  evaluateEmployee: async (data) => {
    try {
      const response = await axiosIns.post(
        "/backoffice/evaluation-period/",
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  reportEmployee: async (id, data) => {
    try {
      const response = await axiosIns.post(
        `/backoffice/evaluation-period/evaluate/${id}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getAssessed: async (queryParams) => {
    try {
      const response = await axiosIns.get(
        `/backoffice/evaluation-period/${queryParams ? "?" + queryParams : ""}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getStatic: async (queryParams) => {
    try {
      const response = await axiosIns.get(
        `/backoffice/evaluation-period/statics/${
          queryParams ? "?" + queryParams : ""
        }`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getMonth: async () => {
    try {
      const response = await axiosIns.get(
        `/backoffice/evaluation-period/month`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  //ADMIN
  getCompanyByMonth: async (queryParams) => {
    try {
      const response = await axiosIns.get(
        `/backoffice/evaluation-period/admin/get-company${
          queryParams ? "?" + queryParams : ""
        }`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getStaticAllCompany: async (queryParams) => {
    try {
      const response = await axiosIns.get(
        `/backoffice/evaluation-period/admin/get-static${
          queryParams ? "?" + queryParams : ""
        }`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getStaticApprove: async (queryParams) => {
    try {
      const response = await axiosIns.get(
        `/backoffice/evaluation-period/admin/get-static-approve${
          queryParams ? "?" + queryParams : ""
        }`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getApproveList: async (queryParams) => {
    try {
      const response = await axiosIns.get(
        `/backoffice/evaluation-period/admin/get-approve-list${
          queryParams ? "?" + queryParams : ""
        }`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  approveEvaluation: async (id, data) => {
    try {
      const response = await axiosIns.post(
        `/backoffice/evaluation-period/admin/approve/${id}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
