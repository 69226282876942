<template>
  <b-row v-if="userData" class="match-height">
    <b-col lg="8" sm="12">
      <b-card class="mb-0">
        <!-- <b-card-header>
        <h4>Employee</h4>
      </b-card-header> -->
        <b-row class="m-2">
          <b-col cols="12" md="3">
            <div class="d-flex flex-column">
              <b-avatar
                :src="userData.avatar"
                rounded="bottom"
                variant="light-dark"
                size="138"
              />
            </div>
          </b-col>
          <b-col cols="12" md="8">
            <table class="mt-2 mt-xl-0 w-100">
              <!-- <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">User ID</span>
            </th>
            <td class="pb-50">
              {{ userData._id }}
            </td>
          </tr> -->
              <tr>
                <th class="pb-50">
                  <feather-icon icon="UserIcon" class="mr-75" />
                  <span class="font-weight-bold">{{ $t("full_name") }}</span>
                </th>
                <td class="pb-50">
                  {{ userData.lastName }}
                  {{ userData.firstName }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="StarIcon" class="mr-75" />
                  <span class="font-weight-bold">{{ $t("department") }}</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ userData.department ? userData.department.name : "-" }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="CreditCardIcon" class="mr-75" />
                  <span class="font-weight-bold">{{ $t("national_id") }}</span>
                </th>
                <td class="pb-50">
                  {{ userData.nationalID }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="CheckIcon" class="mr-75" />
                  <span class="font-weight-bold">{{ $t("status") }}</span>
                </th>
                <td class="pb-50 text-capitalize">
                  <span
                    class="bullet bullet-sm"
                    :class="`bullet-${mappingStatusColor(userData.status)}`"
                  />
                  <span>
                    {{ mappingStatusText(userData.status) }}
                  </span>
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col lg="4" sm="12">
      <b-card title="สถานะการประเมิน" class="mb-0">
        <b-form-group class="mt-1">
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="approveStatusOptions"
            label="title"
            v-model="approveStatus"
            :reduce="(val) => val.value"
          >
            <template #selected-option="{ title, color }">
              <span class="bullet bullet-sm mr-1" :class="`bullet-${color}`" />
              <span>{{ title }}</span>
            </template>
            <template #option="{ title, color }">
              <span class="bullet bullet-sm mr-1" :class="`bullet-${color}`" />
              <span>{{ title }}</span>
            </template>
          </v-select>
        </b-form-group>
        <b-button
          variant="success"
          class="mr-2"
          type="submit"
          @click="evaluateEmployee()"
        >
          {{ $t("evaluations.approve") }}
        </b-button>
      </b-card>
    </b-col>
    <b-col lg="12" sm="12" class="mt-2">
      <b-card :title="$t('evaluation')">
        <span>
          <b>{{ evaluationData.abnormalInformation.category.category }}</b>
          <ul>
            <li
              v-for="reason in evaluationData.abnormalInformation.reason"
              :key="reason"
            >
              {{ reason }}
            </li>
          </ul>
        </span>
        <span>
          <b>รายละเอียดเพิ่มเติม</b>
          <ul>
            <li>
              {{ evaluationData.abnormalInformation.detail }}
            </li>
          </ul>
        </span>
        <span>
          <b>เอกสารหลักฐาน</b>
          <ul>
            <li
              v-for="attachment in evaluationData.abnormalInformation
                .attachment"
              :key="attachment._id"
            >
              <a
                :href="`${`${$apiURL}/files/${attachment.fileName}`}`"
                target="_blank"
                >{{ attachment.fileName }}</a
              >
            </li>
          </ul>
        </span>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormGroup,
  BFormTextarea,
  BAvatar,
  BCardHeader,
  BFormCheckboxGroup,
  BFormCheckbox,
  BForm,
  BFormFile,
} from "bootstrap-vue";
import router from "@/router";
import vSelect from "vue-select";
import { onMounted, ref } from "@vue/composition-api";
import { mappingStatusColor, mappingStatusText } from "@/libs/helper";
import { employeeService } from "@/services/employee";
import { evaluationService } from "@/services/evaluation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
export default {
  components: {
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BButton,
    BFormCheckboxGroup,
    BFormCheckbox,
    BAvatar,
    BForm,
    BFormFile,
    BFormInvalidFeedback,
    vSelect,
  },
  setup() {
    const approveStatusOptions = [
      {
        title: "ปกติ",
        color: "success",
        value: "normal",
      },
      {
        title: "เฝ้าระวัง",
        color: "warning",
        value: "pending",
      },
      {
        title: "อันตราย",
        color: "danger",
        value: "abnormal",
      },
    ];

    const toast = useToast();

    const approveStatus = ref("normal");
    const userData = ref(null);
    const id = router.currentRoute.params.id;
    const evaluationData = ref(null);
    onMounted(() => {
      getEmployee();
      getEvaluation();
    });

    const getEvaluation = () => {
      const evaID = router.currentRoute.query.eva;
      evaluationService
        .viewEvaluation(evaID)
        .then((response) => {
          if (response.status) {
            evaluationData.value = response.data;
          }
        })
        .catch((error) => {
          userData.value = undefined;
        });
    };

    const getEmployee = () => {
      employeeService
        .getData(id)
        .then((response) => {
          if (response.status) {
            userData.value = response.data;
            const imageURL = `${process.env.VUE_APP_API_URL}/files/${response.data.avatar}`;
            userData.value.avatar = imageURL;
          }
        })
        .catch((error) => {
          userData.value = undefined;
        });
    };

    const evaluateEmployee = async () => {
      try {
        const evaID = router.currentRoute.query.eva;

        const approveEvaluation = {
          approveStatus: approveStatus.value,
        };
        const result = await evaluationService.approveEvaluation(
          evaID,
          approveEvaluation
        );

        if (result.status) {
          toast({
            component: ToastificationContent,
            props: {
              title: "ประเมินพนักงานสำเร็จ",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          //Router push to company list
          router.push({ name: "admin-evaluation-approve" }).catch(() => {});
        }
      } catch (e) {
        console.log(e);
      }
    };
    return {
      userData,
      mappingStatusColor,
      mappingStatusText,
      approveStatus,
      evaluationData,
      approveStatusOptions,
      evaluateEmployee,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.upload-image-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid #b3b3b3;
  border-radius: 5px;
  border-style: dotted;
  height: 130px;
  cursor: pointer;
}
.attach-image-box {
  position: relative;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  height: 130px;
}
.attach-image-box .delete {
  position: absolute;
  top: 6px;
  left: 87%;
  background-color: #eeebeb;
  border-radius: 10000px;
  padding: 2px;
  cursor: pointer;
}

.delete {
  width: 20px;
  height: 20px;
  background-color: #eeebeb;
  border-radius: 10000px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  cursor: pointer;
}

.attach-image-box img {
  border-radius: 5px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
</style>
